import {
  Picker,
  Field,
  Popup,
  Button,
  Cell,
  CellGroup,
  Calendar,
  Divider,
  Search,
  ActionSheet,
  Dialog,
  PullRefresh,
  List,
  ImagePreview,
  ShareSheet,
  Sticky,
  Image,
  Skeleton,
  Empty,
  Switch,
  Icon,
  DatetimePicker,
  Tag,
  Collapse,
  CollapseItem,
  ConfigProvider,
  RadioGroup,
  Radio,
  Tab,
  Tabs,
  IndexBar,
  IndexAnchor
} from 'vant'

export function vant(app: any): void {
  app.use(Picker)
  app.use(Field)
  app.use(Popup)
  app.use(Button)
  app.use(Cell)
  app.use(CellGroup)
  app.use(Calendar)
  app.use(Divider)
  app.use(Search)
  app.use(ActionSheet)
  app.use(Dialog)
  app.use(PullRefresh)
  app.use(List)
  app.use(ImagePreview)
  app.use(ShareSheet)
  app.use(Sticky)
  app.use(Image)
  app.use(Skeleton)
  app.use(Empty)
  app.use(Switch)
  app.use(Icon)
  app.use(DatetimePicker)
  app.use(Tag)
  app.use(Collapse)
  app.use(CollapseItem)
  app.use(ConfigProvider)
  app.use(RadioGroup)
  app.use(Radio)
  app.use(Tab)
  app.use(Tabs)
  app.use(IndexBar)
  app.use(IndexAnchor)
}
